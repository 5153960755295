.App {
  text-align: center;
}




.App-header {
  background: rgb(255, 255, 255);
  background: radial-gradient(200.32% 200.63% at 122.90% -22.55%, #3F3F3F 0%, #131313 100%);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  .App-logo {
    height: 30vmin;
    pointer-events: none;
  }
  .Name{
    width: 25%;
    margin-top: 3rem;
  }
  .Tagline{
    width: 20%;
  }
}



@media screen and (max-width: 768px) {
  .App-header {
    min-height: 100vh;
    .App-logo {
      height: 70vmin;
    }
    .Name{
      width: 65%;
      margin-top: 3rem;
    }
    .Tagline{
      width: 60%;
    }
  }
}